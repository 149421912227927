import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { User } from 'libs/core-app/src/lib/state/user/user.model';
const baseUrl = 'https://'+window.location.hostname+':4201/api/login';

//console.log(baseUrl)

@Injectable({
  providedIn: 'root'
})

export class LoginService {
  constructor(private http: HttpClient) { }
  /*
  getAll() {
    return this.http.get(baseUrl);
  }
  create(data) {
    return this.http.post(baseUrl, data);
  }
  update(id, data) {
    return this.http.put(`${baseUrl}/${id}`, data);
  }
  delete(id) {
    return this.http.delete(`${baseUrl}/${id}`);
  }
  deleteAll() {
    return this.http.delete(baseUrl);
  }
  findByTitle(title) {
    return this.http.get(`${baseUrl}?title=${title}`);
  }
  */
  get(mat, pw) {
    return this.http.get(`${baseUrl}/${mat}/${pw}`);
  }
}