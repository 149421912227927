import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { HomeComponent } from './home/home.component';
import { RadarComponent } from './radar/radar.component';
import { SateliteComponent } from './satelite/satelite.component';
import { MapModule } from '../shared/components/map/map.module';
import { RadarMiniComponent } from './radar-mini/radar-mini.component';
import { MaterialModule } from '@funceme/material';
import { RadarMiniCityButtonComponent } from './radar-mini/radar-mini-city-button/radar-mini-city-button.component';
import { RadarMiniSateliteComponent } from './radar-mini/radar-mini-satelite/radar-mini-satelite.component';
import { RadarMiniSourceButtonComponent } from './radar-mini/radar-mini-source-button/radar-mini-source-button.component';
import { LoginComponent } from './login/login.component';
import { AuthComponent } from './auth/auth.component';
import { RadarSafComponent } from './radar-saf/radar-saf.component';

@NgModule({
  declarations: [
    HomeComponent, 
    LoginComponent, 
    AuthComponent, 
    RadarComponent, 
    SateliteComponent, 
    RadarMiniComponent, 
    RadarMiniCityButtonComponent, 
    RadarMiniSateliteComponent, 
    RadarMiniSourceButtonComponent,
    RadarSafComponent
  ],
  imports: [
    CommonModule,
    MapModule,
    MaterialModule,
    ReactiveFormsModule
  ]
})
export class PagesModule { }
