import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapCoreComponent } from './map-core/map-core.component';
import { MapRadarComponent } from './map-radar/map-radar.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    MapCoreComponent,
    MapRadarComponent
  ],
  exports: [
    MapCoreComponent,
    MapRadarComponent
  ],
  imports: [
    CommonModule
  ],
  providers: [
    RouterModule
  ]
})
export class MapModule { }
