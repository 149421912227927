import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { map } from 'rxjs/operators';

import { Uf } from './uf.model';
import { UfService } from './uf.service';

import {
  LoadUfs, UfsLoaded,
  UfActionTypes,
} from './uf.actions';
import { UfState } from './uf.reducer';
import { NotificationsService } from '../../notifications/notifications.service';
import { UfFacade } from './uf.facade';

@Injectable({providedIn: 'root'})
export class UfEffects 
{
  //@Effect() effect$ = this.actions$.ofType(UfsActionTypes.UfsAction);

  @Effect()
  loadUfs$ = this.dataPersistence.fetch(UfActionTypes.LoadUfs, {
    run: (action: LoadUfs, state: any) => {

      if(Object.keys(state.uf.entities).length > 0){
        let allUfs;

        this.ufFacade.allUfs$.subscribe( Ufs => allUfs = Ufs )
        return new UfsLoaded(allUfs);
      }

      return this.ufService
        .all()
        .pipe(
          map((res: Uf[]) => new UfsLoaded(res['data']['list']))
        )
    },

    onError: (action: LoadUfs, error) => {
      this.notifyError.emit(error.statusText);
    }
  });

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<UfState>,
    private ufService: UfService,
    private ufFacade: UfFacade,
    private notifyError: NotificationsService
  ) {}
}
