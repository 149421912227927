import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'libs/core-app/src/lib/services/auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalVars } from '../../shared/global';
import { MapCoreComponent } from '../../shared/components/map/map-core/map-core.component';
import Map from 'ol/Map';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import VectorSource from 'ol/source/Vector.js';
import KML from 'ol/format/KML.js';
import { Icon, Stroke, Style } from 'ol/style.js';
import View from 'ol/View';
import {fromLonLat, transform } from 'ol/proj.js';
import {defaults as defaultControls, Attribution} from 'ol/control.js';

@Component({
  selector: 'app-navigationsaf',
  templateUrl: './navigationsaf.component.html',
  styleUrls: ['./navigationsaf.component.css']
})
export class NavigationSafComponent extends MapCoreComponent implements OnInit {

    logged_user = '';
    logged_user_id = null;
    token;
    authForm: FormGroup;
    FortalezaStatus: boolean;
    QuixeramobimStatus: boolean;
    FortalezaMsg: string;
    QuixeramobimMsg: string;
    highlightRadar: string = null;
    olMap: any;

    @ViewChild('map', { static: true }) map: MapCoreComponent;
    constructor(
        private router: Router,
        private auth: AuthService,
        private http:HttpClient,
        private formBuilder: FormBuilder,
        private activatedroute: ActivatedRoute

    ) {
        //super();
        super(router, activatedroute);
    }

    ngOnInit()
    {
        if (localStorage.getItem('user_name') != 'null') {
            this.logged_user = localStorage.getItem('user_name');
            this.logged_user_id = localStorage.getItem('user_id');
        } else {
            this.logged_user = 'Visitante';
            this.logged_user_id = null;
        }
        this.token = localStorage.getItem('app_user|access_token');

    }

    logout()
    {
        this.auth.logout();
        this.router.navigate(['/login']);
    }

    login()
    {
        this.router.navigate(['/auth']);
    }

    async linkRadar(radar: string, produto: string) {

        GlobalVars.currentRadar = radar;
        GlobalVars.currentProduct = produto;
        await this.router.navigate([GlobalVars.getSafRoute()]);
    }

    async linkCamada(camada: string) {
        GlobalVars.currentLayer = camada;
        await this.router.navigate([GlobalVars.getSafRoute()]);
    }

    ngAfterViewInit(): void {

    }

}
