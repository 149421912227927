import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { map } from 'rxjs/operators';

import { User, RestUserListResponse, RestUserResponse } from './user.model';
import { UserService } from './user.service';

import {
  LoadUsers, 
  UsersLoaded,
  UserActionTypes,
  UsersAlreadyLoaded,
  PickUser,
  UserPicked,
  ActionFailed,
  SaveUser,
  DestroyUser,
  UserDestroyed
} from './user.actions';
import { UserState } from './user.reducer';
import { NotificationsService } from '../../notifications/notifications.service';

@Injectable({providedIn: 'root'})
export class UserEffects 
{
  //@Effect() effect$ = this.actions$.ofType(UsersActionTypes.UsersAction);

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<UserState>,
    private UserService: UserService,
    private notifyError: NotificationsService
  ) { }

  @Effect()
  loadUsers$ = this.dataPersistence.fetch(UserActionTypes.LoadUsers, {
    run: (action: LoadUsers, state: any) => {

      if (this.isValid(action.payload, state.user))
        return new UsersAlreadyLoaded();

      return this.UserService
        .all()
        .pipe(
          map((res: RestUserListResponse) => new UsersLoaded(
            { 
              list: res['data']['list'],
              queue_in: Number(res['meta']['cache']['queue_in']),
              expires_in: Number(res['meta']['cache']['expires_in']),
              cached_at: new Date(res['meta']['cache']['cached_at']['date']),
              loaded_at: new Date()
            }))
        );
    },

    onError: (action: LoadUsers, error) => {
      //console.log(error);
      this.notifyError.emit(error.message);
      return new ActionFailed();
    }
  });

  @Effect()
  pickUser$ = this.dataPersistence.fetch(UserActionTypes.PickUser, {
    run: (action: PickUser, state: any) => {
      return this.UserService
        .pick(action.payload)
        .pipe(
          map((res: RestUserResponse) => new UserPicked(res['data']))
        );
    },

    onError: (action: PickUser, error) => {
      this.notifyError.emit(error.statusText);
      return new ActionFailed();
    }
  });

  @Effect()
  saveUser$ = this.dataPersistence.fetch(UserActionTypes.SaveUser, {
    run: (action: SaveUser, state: any) => {
      return this.UserService
        .save(action.payload)
        .pipe(
          map((res: RestUserResponse) => new UserPicked(res['data']))
        );
    },

    onError: (action: SaveUser, error) => {
      this.notifyError.emit(error.statusText);
      return new ActionFailed();
    }
  });

  @Effect()
  destroyUser$ = this.dataPersistence.fetch(UserActionTypes.DestroyUser, {
    run: (action: DestroyUser, state: any) => {
      return this.UserService
        .destroy(action.payload)
        .pipe(
          map((res) => new UserDestroyed())
        );
    },

    onError: (action: DestroyUser, error) => {
      this.notifyError.emit(error.statusText);
      return new ActionFailed();
    }
  });

  private isValid (payload, state): boolean
  {
    if (payload == true || Object.keys(state.entities).length == 0)
      return false;
    
    let lowerTime = (state.queue_in < state.expires_in)? state.queue_in : state.expires_in;

    let now = new Date;
    let time = new Date(state.loaded_at.getTime());
    time.setSeconds(time.getSeconds() + lowerTime);

    return now.getTime() < time.getTime();  
  }
}
