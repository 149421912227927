import { ActionReducerMap} from '@ngrx/store';
import * as fromUf from './uf/uf.reducer';
import * as fromConfig from './config/config.reducer';
import * as fromUser from './user/user.reducer';

// Single Source of Truth
export interface AppState {
    uf: fromUf.UfState,
    config: fromConfig.ConfigState,
    user: fromUser.UserState,
} 

export const reducers: ActionReducerMap<AppState> = { 
    uf: fromUf.ufReducer,
    config: fromConfig.configReducer,
    user: fromUser.userReducer,
};