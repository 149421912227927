import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { UserActions, UserActionTypes } from './user.actions';
import { User } from './user.model';

/**
 * Interface to the part of the Store containing UserState
 * and other information related to UserData.
 */
export interface UserState extends EntityState<User> {
  selectedId: number;
  loading: boolean;
  loaded_at: Date;
  cached_at: Date;
  queue_in: number,
  expires_in: number
}

export const adapter: EntityAdapter<User> = createEntityAdapter<User>({
  selectId: (user: User) => user.id,
});

export const initialState: UserState = adapter.getInitialState({
  selectedId: null,
  loading: false,
  loaded_at: null,
  cached_at: null,
  queue_in: null,
  expires_in: null
});

export function userReducer(state = initialState, action: UserActions): UserState {

  switch (action.type) {
    case UserActionTypes.UserSelected: {
      return Object.assign({}, state, { selectedId: action.payload });
    }

    case UserActionTypes.LoadUsers: {
      return Object.assign({}, state, { loading: true });
    }
   
    case UserActionTypes.UsersLoaded: {
      state.loading     = false;
      state.cached_at   = action.payload.cached_at;
      state.loaded_at   = action.payload.loaded_at;
      state.queue_in    = action.payload.queue_in;
      state.expires_in  = action.payload.expires_in;
      return adapter.addAll(action.payload.list, state);
    }

    case UserActionTypes.SaveUser:
    case UserActionTypes.PickUser: {
      return Object.assign({}, state, { loading: true });
    }

    case UserActionTypes.UserPicked: {
      state.loading     = false;
      state.selectedId = action.payload.id;
      return adapter.upsertOne(action.payload, state);
    }

    case UserActionTypes.DestroyUser: {
      state.selectedId = null;
      return adapter.removeOne(action.payload, state);
    }

    case UserActionTypes.UsersAlreadyLoaded: {
      return Object.assign({}, state, { loading: false });
    }

    case UserActionTypes.ActionFailed: {
      return Object.assign({}, state, { loading: false });
    }
    
    default:
      return state;
  }
}

export const getSelectedUserId  = (state: UserState) => state.selectedId;
export const isLoadingUsers     = (state: UserState) => state.loading;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of User ids
export const selectUserIds = selectIds;

// select the dictionary of User entities
export const selectUserEntities = selectEntities;

// select the array of User
export const selectAllUsers = selectAll;

// select the total User count
export const selectUserTotal = selectTotal;
