import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'libs/core-app/src/lib/services/auth/auth.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})

export class AuthComponent implements OnInit {
  
  authForm: FormGroup;
  showAlertErr: boolean = false; // hidden by default
  showAlertSuccess: boolean = false; // hidden by default
  FortalezaStatus: boolean;
  QuixeramobimStatus: boolean;
  FortalezaMsg: string;
  QuixeramobimMsg: string;

  constructor(
    private router: Router, 
    private auth: AuthService, 
    private https:HttpClient, 
    private formBuilder: FormBuilder,
  ) { }
  
  public url = 'https://'+window.location.hostname+':4201';

  ngOnInit() {

    this.authForm = this.formBuilder.group({
      FortalezaStatus:false,
      QuixeramobimStatus:false,
      FortalezaMsg:'',
      QuixeramobimMsg:''
    });
    /*
    this.https.get('https://cdn.funceme.br/meteorologia/radar/alert.json')
      .subscribe(
        data => {
          this.FortalezaStatus = data['Fortaleza']['alert'] == 1 ? true : false;
          this.QuixeramobimStatus = data['Quixeramobim']['alert'] == 1 ? true : false;
          this.FortalezaMsg = data['Fortaleza']['msg'];
          this.QuixeramobimMsg = data['Quixeramobim']['msg'];
    });
    */
    /*
    this.FortalezaStatus = true;
    this.QuixeramobimStatus = false;
    this.FortalezaMsg = '111';
    this.QuixeramobimMsg = '222';
    */
  }

  logout() {
      this.auth.logout();
      this.router.navigate(['/login']);
  }

  checkValue(values:any, field):void{
    if (field == 'FortalezaStatus'){
      this.FortalezaStatus = values.currentTarget.checked;
    } else {
      this.QuixeramobimStatus = values.currentTarget.checked;
    }
  }

  upload() {

    const FortalezaSts = this.authForm.controls.FortalezaStatus.value;
    const QuixeramobimSts = this.authForm.controls.QuixeramobimStatus.value;
    const FortalezaMsg = this.authForm.controls.FortalezaMsg.value;
    const QuixeramobimMsg = this.authForm.controls.QuixeramobimMsg.value;

    const path = this.url+'/api/uploadFile/'+FortalezaSts+'/'+QuixeramobimSts+'/'+FortalezaMsg+'/'+QuixeramobimMsg;

    fetch(`${path}`).then((response) => {
      if(response.status == 200){
        this.showAlertErr = true;
        this.showAlertSuccess = false;
      } else {
        this.showAlertErr = false;
        this.showAlertSuccess = true;  
      }
    }).catch((e) => {
      this.showAlertErr = false;
      this.showAlertSuccess = true;
    });
    return;
    
  }

}