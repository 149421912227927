import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule, ActionReducer, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { NxModule } from '@nrwl/nx';
import { MatSnackBarModule } from '@angular/material/snack-bar'; 

import { reducers } from '../state';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { UfEffects } from './uf/uf.effects';
import { UserEffects } from './user/user.effects';

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({keys: [ 
    'uf',
    'user', 
    'config', 
  ],rehydrate: true})(reducer);
}
const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
  imports: [
    CommonModule,
    MatSnackBarModule,
    NxModule.forRoot(),
    StoreModule.forRoot(reducers, {metaReducers}),
    StoreDevtoolsModule.instrument(
      { 
        maxAge: 10 
      }
    ),
    EffectsModule.forRoot([
      UfEffects,
      UserEffects
    ]),
  ],
  declarations: []
})
export class StateModule { }