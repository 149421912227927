import * as fromConfig from './config.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { emptyConfig } from './config.model';

export const selectConfigState = createFeatureSelector<fromConfig.ConfigState>('config');

export const selectConfigEntities = createSelector(
  selectConfigState,
  fromConfig.selectConfigEntities
);

export const selectCitySelected = createSelector(
  selectConfigState,
  fromConfig.getCitySelected
);

export const selectSourceSelected = createSelector(
  selectConfigState,
  fromConfig.getSourceSelected
);

export const selectCurrentConfig = createSelector(
  selectConfigEntities,
  (configEntities) => {
    return configEntities? configEntities[1] : emptyConfig;
  }
);