import * as fromUf from './uf.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { emptyUf, Uf } from './uf.model';

export const selectUfState = createFeatureSelector<fromUf.UfState>('uf');

export const selectUfIds = createSelector(
  selectUfState,
  fromUf.selectUfIds
);

export const selectUfEntities = createSelector(
  selectUfState,
  fromUf.selectUfEntities
);

export const selectAllUfs = createSelector(
  selectUfState,
  fromUf.selectAllUfs
);

export const selectCurrentUfIds = createSelector(
  selectUfState,
  fromUf.getSelectedUfIds
);

export const selectCurrentUfs = createSelector(
  selectUfEntities,
  selectCurrentUfIds,
  (ufEntities, ufIds) => {

    let list: Uf[] = [];

    ufIds.forEach(element => {
      list.push(ufEntities[element]);
    });

    return list;
  }
);

export const selectSoughtUfIds = createSelector(
  selectUfState,
  fromUf.getSoughtUfIds
);

export const selectSoughtUfs = createSelector(
  selectUfEntities,
  selectSoughtUfIds,
  (ufEntities, ufIds) => {

    let list: Uf[] = [];

    ufIds.forEach(element => {
      list.push(ufEntities[element]);
    });

    return list;
  }
);