export { CoreDataModule }       from './lib/core-app.module';

export { Config, emptyConfig, ConfigProduto, ConfigPeriodo, ConfigMetrica }  from './lib/state/config/config.model';
export { ConfigFacade }             from './lib/state/config/config.facade';

export { Uf }                   from './lib/state/uf/uf.model';
export { UfService }            from './lib/state/uf/uf.service';
export { UfFacade }             from './lib/state/uf/uf.facade';

export { GenericFacade }        from './lib/generics/generic-facade';
