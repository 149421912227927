import { Injectable } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';
import * as UfActions from './uf.actions';
import { UfState } from './uf.reducer';
import { selectAllUfs, selectCurrentUfs, selectSoughtUfs } from './uf.selectors';

@Injectable({ providedIn: 'root' })
export class UfFacade {
  allUfs$ = this.store.pipe(select(selectAllUfs));
  currentUfs$ = this.store.pipe(select(selectCurrentUfs));
  soughtUfs$ = this.store.pipe(select(selectSoughtUfs));

  constructor(private store: Store<UfState>, private actions$: ActionsSubject) {}

  selectUf(uf: string) {
    this.store.dispatch(new UfActions.UfSelected(uf));
  }

  unselectUf(uf: string) {
    this.store.dispatch(new UfActions.UfUnselected(uf));
  }

  soughtUf(ufs: string) {
    this.store.dispatch(new UfActions.UfsSought(ufs));
  }

  loadUfs() {
    this.store.dispatch(new UfActions.LoadUfs());
  }
}
