import { Action } from '@ngrx/store';
import { UserResponsePayload, User } from './user.model';

export enum UserActionTypes {
  UsersAction         = '[Users] Action',
  LoadUsers           = '[Users] Load Data',
  UsersLoaded         = '[Users] Data Loaded',
  UserSelected        = '[Users] Selected',
  UsersAlreadyLoaded  = '[Users] Already Loaded',
  PickUser            = '[Users] Pick User',
  UserPicked          = '[Users] User Picked',
  DestroyUser         = '[Users] Destroy User',
  UserDestroyed       = '[Users] User Destroyed',
  SaveUser            = '[Users] Save User',
  ActionFailed        = '[Users] Action Failed',}

  export class Users implements Action {
    readonly type = UserActionTypes.UsersAction;
  }
  
  export class LoadUsers implements Action {
    readonly type = UserActionTypes.LoadUsers;
    constructor(public payload: boolean) { }
  }
  
  export class UsersLoaded implements Action {
    readonly type = UserActionTypes.UsersLoaded;
    constructor(public payload: UserResponsePayload) { }
  }
  
  export class UserSelected implements Action {
    readonly type = UserActionTypes.UserSelected;
    constructor(public payload: number) { }
  }
  
  export class UsersAlreadyLoaded implements Action {
    readonly type = UserActionTypes.UsersAlreadyLoaded;
    constructor() { }
  }
  
  export class PickUser implements Action {
    readonly type = UserActionTypes.PickUser;
    constructor(public payload: number) { }
  }
  
  export class UserPicked implements Action {
    readonly type = UserActionTypes.UserPicked;
    constructor(public payload: User) { }
  }
  
  export class DestroyUser implements Action {
    readonly type = UserActionTypes.DestroyUser;
    constructor(public payload: number) { }
  }
  
  export class UserDestroyed implements Action {
    readonly type = UserActionTypes.UserDestroyed;
    constructor() { }
  }
  
  export class SaveUser implements Action {
    readonly type = UserActionTypes.SaveUser;
    constructor(public payload: User) { }
  }
  
  export class ActionFailed implements Action {
    readonly type = UserActionTypes.ActionFailed;
    constructor() { }
  }
  
  export type UserActions = Users
    | LoadUsers
    | UsersLoaded
    | UserSelected
    | UsersAlreadyLoaded
    | PickUser
    | UserPicked
    | DestroyUser
    | UserDestroyed
    | SaveUser
    | ActionFailed
  ;