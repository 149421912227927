import { Observable } from 'rxjs';

export abstract class GenericFacade 
{
    loading$: Observable<any>;
    
    abstract select(id: number);

    abstract load(reset: boolean);

    abstract pick(id: number);

    abstract destroy(id: number);

    abstract save(model: any);

    abstract index(): void;

    abstract edit(id: number): void;

    abstract show(id: number): void;

    abstract create(): void;

    abstract refresh(): void;
}