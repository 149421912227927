import { Action } from '@ngrx/store';
import { Uf } from './uf.model';

export enum UfActionTypes {
  UfsAction = '[Ufs] Action',
  LoadUfs = '[Ufs] Load Data',
  UfsLoaded = '[Ufs] Data Loaded',
  UfSelected = '[Ufs] Selected',
  UfUnselected = '[Ufs] Unselected',
  UfsSought = '[Ufs] Sought',
}

export class Ufs implements Action {
  readonly type = UfActionTypes.UfsAction;
}

export class LoadUfs implements Action {
  readonly type = UfActionTypes.LoadUfs;
  constructor() { }
}

export class UfsLoaded implements Action {
  readonly type = UfActionTypes.UfsLoaded;
  constructor(public payload: Uf[]) { }
}

export class UfSelected implements Action {
  readonly type = UfActionTypes.UfSelected;
  constructor(public payload) { }
}

export class UfUnselected implements Action {
  readonly type = UfActionTypes.UfUnselected;
  constructor(public payload) { }
}

export class UfsSought implements Action {
  readonly type = UfActionTypes.UfsSought;
  constructor(public payload) { }
}

export type UfActions = Ufs
  | LoadUfs
  | UfsLoaded
  | UfUnselected
  | UfSelected
  | UfsSought
;