export class GlobalVars {

    public static currentRadar: string = "RMT0100DS";
    public static currentProduct: string = "prsf";
    public static currentLayer: string = "MACRORREGIAO";
    public static currentLayout: string = "full";

    public static mapNumberOfImages =
    {
      "prsf" :
      {
        current: 20,
        max: 288
      },
      "acum-diario" :
      {
        current: 7,
        max: 31
      },
      "acum-horario" :
      {
        current: 7,
        max: 24
      }
    }

  public static showLegend = true;
  public static velocity = "2"

  public static dateRadar = "0";
  public static getStringRoute()
  {
    //return "radar/" + GlobalVars.currentRadar + "/" + GlobalVars.currentProduct  + "/"  + GlobalVars.currentLayer + "/" + GlobalVars.mapNumberOfImages[GlobalVars.currentProduct].current + "/" + GlobalVars.showLegend + "/" + GlobalVars.velocity + "/" + GlobalVars.dateRadar + "/" + GlobalVars.currentLayout ;
    return "radar/" + GlobalVars.currentRadar + "/" + GlobalVars.currentProduct  + "/"  + GlobalVars.currentLayer + "/" + GlobalVars.mapNumberOfImages[GlobalVars.currentProduct].current + "/" + GlobalVars.showLegend + "/" + GlobalVars.velocity + "/" + GlobalVars.dateRadar;
  }

  public static getSafRoute()
  {
    return "saf/" + "radar/" + GlobalVars.currentRadar + "/" + GlobalVars.currentProduct  + "/"  + GlobalVars.currentLayer + "/" + GlobalVars.mapNumberOfImages[GlobalVars.currentProduct].current + "/" + GlobalVars.showLegend + "/" + GlobalVars.velocity + "/" + GlobalVars.dateRadar;

  }
}
