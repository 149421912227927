import { Injectable } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';

import * as ConfigActions from './config.actions';
import { ConfigState } from './config.reducer';
import { selectCurrentConfig, selectCitySelected, selectSourceSelected } from './config.selectors';
import { Config } from './config.model';

@Injectable({ providedIn: 'root' })
export class ConfigFacade {

  current$ = this.store.pipe(select(selectCurrentConfig));
  citySelected$ = this.store.pipe(select(selectCitySelected));
  sourceSelected$ = this.store.pipe(select(selectSourceSelected));

  constructor(private store: Store<ConfigState>, private actions$: ActionsSubject) {}

  toggleFullscreen() {
    this.store.dispatch(new ConfigActions.ToggleFullscreenAction());
  }

  mini() {
    this.store.dispatch(new ConfigActions.MiniAppSelected());
  }

  large() {
    this.store.dispatch(new ConfigActions.LargeAppSelected());
  }

  toggleFortaleza() {
    this.store.dispatch(new ConfigActions.ToggleFortaleza());
  }

  toggleQuixeramobim() {
    this.store.dispatch(new ConfigActions.ToggleQuixeramobim());
  }

  toggleRadar() {
    this.store.dispatch(new ConfigActions.ToggleRadar());
  }

  toggleSatelite() {
    this.store.dispatch(new ConfigActions.ToggleSatelite());
  }

  save($payload: Config) {
    this.store.dispatch(new ConfigActions.SaveConfig($payload));
  }
}
