import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { timeout } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class RadarProdutoGeradoService {

    private env = environment;
    private urn = 'rpc/v1/produto-gerado';

    private radar: string;
    private produto: string;
    private tempo: number;
    private data: string;
    private layout: string;

    private serviceListener = new Subject<any>();
    public  serviceListener$ = this.serviceListener.asObservable();


    constructor(private http: HttpClient)
    {

    }

    public forRadar(radar: string)
    {
      this.radar = radar;
      return this;
    }

    public forProduto(produto: string)
    {
        this.produto = produto;
        return this;
    }

    public inTime(tempo: number)
    {
      this.tempo = tempo;
      return this;
    }

    public forData(data: string)
    {
      this.data = data;
      return this;
    }

    public forLayout(layout: string)
    {
      this.layout = layout;
      return this;
    }

    public get(callback?, errorHandler?): void
    {
        let url = this.env.apiEndpoint + this.urn;

        let params = '?radar=' + this.radar;

        params += '&produto=' + this.produto;

        if (this.tempo)
          params += '&tempo=' + this.tempo;

        if (this.data)
          params += '&data=' + this.data

        if (this.layout)
          params += '&layout=' + this.layout

        params += '&cache=no'

        this.http.get(url+params).pipe(timeout(10000)).subscribe(
        (response) => {
            if (callback){
                callback(response);
            }
            this.serviceListener.next(response);
        }, 
        (error) => {
          if (error.name === "TimeoutError") {
            this.serviceListener.next({ error: true, message: 'Timeout ao acessar a API' });
        } else {
            this.serviceListener.next({ error: true, message: 'Falha ao consumir a API' });
        }
      }
  );
}
}
