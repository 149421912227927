import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor 
{
    refreshTokenInProgress = false;

    tokenRefreshedSource = new Subject();
    tokenRefreshed$ = this.tokenRefreshedSource.asObservable();
    
    constructor() { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> 
    {
        if (request.url.indexOf('oauth') === -1)
            request = this.addTokenHeader(request);

        return next.handle(request);
    }

    addTokenHeader(request) 
    {
        /*const token = localStorage.getItem('access_token');

        if (token != null) {
            request = request.clone({
            setHeaders: {
                Authorization: token
            }
            });
        }*/
        return request;
    }
}
