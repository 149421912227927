import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../../shared/services/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'libs/core-app/src/lib/services/auth/auth.service';
import { Md5 } from 'ts-md5/dist/md5';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  user: string;
  password: string;
  loading: boolean;
  loginError: string;
  providers: [Md5];
  showAlertErr: boolean = false ; // hidden by default

  constructor(
    private formBuilder: FormBuilder, 
    private loginService: LoginService, 
    private router: Router, 
    public auth: AuthService
  ){ }

  ngOnInit(): void {
      this.loginForm = this.formBuilder.group({
        userMat: ['', Validators.required],
        password: ['', Validators.required]
      });
  }

  async login(){
    
    //API PATH
    //http://localhost:4201/api/login/9113/81dc9bdb52d04dc20036dbd8313ed055
    
    const md5 = new Md5();
    const userMat = this.loginForm.controls.userMat.value;
    const password = Md5.hashStr(this.loginForm.controls.password.value);
    
    this.loginService.get(userMat, password)
      .subscribe(
          data => {
            this.showAlertErr = false;
            if (data) {
              localStorage.setItem('app_user|access_token', data['access_token']);
              this.auth.isAuthenticated();
              this.router.navigate(['/auth']);
              /* this.router.navigate(['/auth']).then(() => {
                location.reload();
              }); */
            }
          },
          data => {
            this.loading = false;
            if (data.error && data.error.message) {
              this.loginError = data.error.message;
              //alert('Matrícula ou senha estão incorretos.');
              this.loginForm.controls.userMat.setValue(null);
              this.loginForm.controls.password.setValue(null);
              this.showAlertErr = true;
              return;

            }
          }
      );
  }
  
  logOutUser(){
    this.auth.logout();
    location.reload();    
  }

}