import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RootComponent } from './root/root.component';
import { RootSafComponent } from './root-saf/root-saf.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AppRoutingModule } from '../app-routing.module';
import { NavigationComponent } from './navigation/navigation.component';
import { NavigationSafComponent } from './navigationsaf/navigationsaf.component';
import { BrowserAnimationsModule }  from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';

import { MaterialModule } from '@funceme/material';

@NgModule({
  declarations: [
    RootComponent,
    RootSafComponent, 
    HeaderComponent, 
    FooterComponent, 
    NavigationComponent,
    NavigationSafComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MaterialModule, 
    FormsModule
  ]
})
export class LayoutModule { }
