import { Component, OnInit } from '@angular/core';
import { ConfigFacade } from '@funceme/core-app';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-radar-mini-city-button',
  templateUrl: './radar-mini-city-button.component.html',
  styleUrls: ['./radar-mini-city-button.component.css']
})
export class RadarMiniCityButtonComponent implements OnInit {

  public radarBanda = 'radars';

  private subscriptions = new Subscription();

  constructor(private configFacade: ConfigFacade) {
    this.subscriptions.add(this.configFacade.citySelected$.subscribe(city => {
      if (city == 'fortaleza'){
        this.radarBanda = 'radarx';

      } else {
        this.radarBanda = 'radars';

      }
    }))
  }

  ngOnInit() {
    this.configFacade.toggleQuixeramobim();
  }

  localChange(value) {
    if (value == 'radarx')
      this.configFacade.toggleFortaleza();

    else
      this.configFacade.toggleQuixeramobim();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
