import { Action } from '@ngrx/store';

import { Config } from './config.model';

export enum ConfigActionTypes {
  ConfigAction            = '[Config] Action',
  ToggleFullscreenAction  = '[Config] Toggle Fullscreen',
  ToggleFortaleza         = '[Config] Toggle Fortaleza',
  ToggleQuixeramobim      = '[Config] Toggle Quixeramobim',
  ToggleRadar             = '[Config] Toggle Radar',
  ToggleSatelite          = '[Config] Toggle Satelite',
  MiniAppSelected         = '[Config] Mini App Selected',
  LargeAppSelected        = '[Config] Large App Selected',
  SaveConfig              = '[Config] Save Config',
}

export class Configs implements Action {
  readonly type = ConfigActionTypes.ConfigAction;
}

export class SaveConfig implements Action {
  readonly type = ConfigActionTypes.SaveConfig;
  constructor(public payload: Config) { }
}

export class ToggleFullscreenAction implements Action {
  readonly type = ConfigActionTypes.ToggleFullscreenAction;
  constructor() { }
}

export class ToggleFortaleza implements Action {
  readonly type = ConfigActionTypes.ToggleFortaleza;
  constructor() { }
}

export class ToggleQuixeramobim implements Action {
  readonly type = ConfigActionTypes.ToggleQuixeramobim;
  constructor() { }
}

export class ToggleRadar implements Action {
  readonly type = ConfigActionTypes.ToggleRadar;
  constructor() { }
}

export class ToggleSatelite implements Action {
  readonly type = ConfigActionTypes.ToggleSatelite;
  constructor() { }
}

export class MiniAppSelected implements Action {
  readonly type = ConfigActionTypes.MiniAppSelected;
  constructor() { }
}

export class LargeAppSelected implements Action {
  readonly type = ConfigActionTypes.LargeAppSelected;
  constructor() { }
}

export type ConfigActions = Configs
  | ToggleFullscreenAction
  | ToggleFortaleza
  | ToggleQuixeramobim
  | ToggleRadar
  | ToggleSatelite
  | MiniAppSelected
  | LargeAppSelected
  | SaveConfig
;
