import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ConfigFacade } from 'libs/core-app/src/lib/state/config/config.facade';
import { ConfigPeriodo, ConfigProduto, emptyConfig, Config } from 'libs/core-app/src/lib/state/config/config.model';
import { GlobalVars } from '../../shared/global';

declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.css']
})
export class RootComponent implements OnInit {

  @ViewChild('sidenav',null) sidenav: MatSidenav;

  public fullscreen: boolean = false;

  public config: Config;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private configFacade: ConfigFacade
  ) {

    const navEndEvents$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    );

    navEndEvents$.subscribe((event: NavigationEnd) => {
      gtag('config', 'UA-7298747-3',{
          'page_path': event.urlAfterRedirects
      });
    })

  }

  ngOnInit() {

  }

  close()
  {
    this.sidenav.close();
  }

  link(url) {
    this.close();
    this.router.navigate([url]);
  }

  private navigate()
  {
    // let route = this.config.periodo + '/' + this.config.produto + '/' + this.config.metrica + '/' + this.config.ano;

    // if (this.config.mes)
    //   route += '/' + this.config.mes;

    // if (this.config.dia)
    //   route += '/' + this.config.dia;

    // this.router.navigate([route]);

    // this.config.redirect = false;
    // this.configFacade.save(this.config);
  }

  async linkRadar(radar: string, produto: string)
  {

    GlobalVars.currentRadar = radar;
    GlobalVars.currentProduct = produto;

    await this.router.navigate([GlobalVars.getStringRoute()]);
  }

  async linkCamada(camada: string)
  {
    GlobalVars.currentLayer = camada;
    await this.router.navigate([GlobalVars.getStringRoute()]);
  }



}
