export enum CidadesRadar {
    QUIXERAMOBIM    = 'quixeramobim',
    FORTALEZA       = 'fortaleza',
}

export enum FonteImagems {
    RADAR           = 'radar',
    SATELITE        = 'satelite',
}


export enum ConfigProduto {
    MUNICIPIOS              = 'municipios',
    MACRORREGIOES           = 'macrorregioes',
    SUB_BACIAS              = 'sub-bacias',
    REGIOES_HIDROGRAFICAS   = 'regioes-hidrograficas',
    CEARA                   = 'ceara',
    RESERVATORIOS           = 'reservatorios'
}

export enum ConfigPeriodo {
    DIA             = 'dia',
    DIARIO          = 'diario',
    MENSAL          = 'mensal',
    MES             = 'mes',
    BIMESTRAL       = 'bimestral',
    TRIMESTRAL      = 'trimestral',
    QUADRIMESTRAL   = 'quadrimestral',
    ANUAL           = 'anual'
}

export enum ConfigMetrica {
    MEDIA       = 'media',
    MAXIMA      = 'maxima',
    INTERPOLADA = 'interpolada'
}

export interface Config {
    id: number;
    cidade: string;
    fonte: string;
}

export const emptyConfig: Config = {
    id: 1,
    cidade: CidadesRadar.FORTALEZA,
    fonte: FonteImagems.RADAR
}