import { Component, OnInit } from '@angular/core';
import { ConfigFacade } from '@funceme/core-app';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-radar-mini-source-button',
  templateUrl: './radar-mini-source-button.component.html',
  styleUrls: ['./radar-mini-source-button.component.css']
})
export class RadarMiniSourceButtonComponent implements OnInit {

  public sourceValue = 'radar';

  private subscriptions = new Subscription();


  constructor(private configFacade: ConfigFacade) {
    this.subscriptions.add(this.configFacade.sourceSelected$.subscribe(source => {

      this.sourceValue = source;

    }))
  }

  ngOnInit() {
  }

  sourceChange(value) {
    
    if (value == 'radar'){

      this.configFacade.toggleRadar();
    } else {

      this.configFacade.toggleSatelite();
    }
  }

}
