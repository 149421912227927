import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { UfActions, UfActionTypes } from './uf.actions';
import { Uf } from './uf.model';

/**
 * Interface to the part of the Store containing UfState
 * and other information related to UfData.
 */
export interface UfState extends EntityState<Uf> {
  selectedUfIds: string[];
  loaded_at: Date | null;
  soughtUfIds: string[];
  sought_at: Date | null;
}

export const adapter: EntityAdapter<Uf> = createEntityAdapter<Uf>({
  selectId: (uf: Uf) => uf.id,
});

export const initialState: UfState = adapter.getInitialState({
  selectedUfIds: [],
  loaded_at: null,
  soughtUfIds: [],
  sought_at: null
});

export function ufReducer(state = initialState, action: UfActions): UfState {

  switch (action.type) {
    case UfActionTypes.UfSelected: {
      return Object.assign({}, state, { selectedUfIds: [...state.selectedUfIds, action.payload+''] });
    }

    case UfActionTypes.UfUnselected: {
      return Object.assign({}, state, { 
        selectedUfIds: state.selectedUfIds.filter(id => id !== action.payload+'')
      });
    }

    case UfActionTypes.UfsSought: {
      if (!action.payload) {
        return Object.assign({}, state, { soughtUfIds: []})
      }
      return Object.assign({}, state, { soughtUfIds: action.payload.split(',').map(value => value+'') });
    }

    case UfActionTypes.UfsLoaded: {
      return adapter.addAll(action.payload, state);
    }
    
    default:
      return state;
  }
}

export const getSelectedUfIds = (state: UfState) => state.selectedUfIds;
export const getSoughtUfIds = (state: UfState) => state.soughtUfIds;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of uf ids
export const selectUfIds = selectIds;

// select the dictionary of uf entities
export const selectUfEntities = selectEntities;

// select the array of uf
export const selectAllUfs = selectAll;

// select the total uf count
export const selectUfTotal = selectTotal;
