import { Component, OnInit, ViewChild } from '@angular/core';
import { MapCoreComponent } from '../map-core/map-core.component';
import ContextMenu from 'ol-contextmenu/dist/ol-contextmenu.js';
import { NavigationComponent } from 'apps/public/src/app/layout/navigation/navigation.component';

@Component({
  selector: 'app-map-radar',
  templateUrl: './map-radar.component.html',
  styleUrls: ['./map-radar.component.css']
})
export class MapRadarComponent implements OnInit {

  public mapOptions: any;

  @ViewChild('map', {static: true}) map: MapCoreComponent;

  constructor() { 


    this.mapOptions = {
      center: [-39.6, -5.2],
      zoom: 7,
      on: {
        singleclick: () => {
          // TODO
        }
      }
    }
  }

  ngOnInit() 
  {
    
  }

  ngAfterViewInit(): void {

    var contextmenu = new ContextMenu({
      width: 100,
      defaultItems: false,
      items: [
        {
          text: 'Reportar',
          icon: 'assets/icon/warning_24.png', 
          callback: (evt, map) => {
            // TODO
          } 
        }
      ]
    });

    contextmenu.on('beforeopen', (evt) => {
      var feature = this.map.olMap.forEachFeatureAtPixel(evt.pixel, function (ft, l) {
        return ft;
      });
    
      if (feature) { 
        contextmenu.enable();
      } else {
        contextmenu.disable();
      }
    });

    contextmenu.on('open', (evt) => {
      var feature = this.map.olMap.forEachFeatureAtPixel(evt.pixel, function(ft, l) {
        return ft;
      });

      if (feature) {
        // 
      }
    });

    this.map.olMap.addControl(contextmenu);
  }
}
