import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment, authConfig } from '@env/environment';
import { Login } from './login.model';
import { User } from '../../state/user/user.model';

@Injectable({ providedIn: 'root' })
export class AuthService 
{
    private callAuthService = new Subject<any>();
    callAuthService$ = this.callAuthService.asObservable();

    constructor(public jwtHelper: JwtHelperService, protected http: HttpClient) 
    { 
        
    }

    getUrl() {
        return `${environment.apiEndpoint}oauth/token`;
    }
    

    public isTokenExpired(): boolean
    {
        return this.jwtHelper.isTokenExpired(localStorage.getItem('app_user|access_token'));
    }

    public isAuthenticated(): boolean 
    {
        const token = localStorage.getItem('app_user|access_token');

        if (token == 'null' || token == null)
            return false;

        return !this.jwtHelper.isTokenExpired(token);
    }

    public login(username, password): Observable<Login>
    {
        return this.http.post<Login>(this.getUrl(), {
            grant_type :    "password",
            client_id:      authConfig.client_id,
            client_secret:  authConfig.client_secret,
            username:       username,
            password:       password,
            scope:          "*"
        });        
    }

    public saveTokens(access_token: string, refresh_token:string)
    {
        localStorage.setItem('app_user|access_token', access_token);
        localStorage.setItem('app_user|refresh_token', refresh_token);
    }

    public saveUser(user: User)
    {
        localStorage.setItem('app_user|user', JSON.stringify(user));
    }

    public getUser(): User
    {
        return JSON.parse(localStorage.getItem('app_user|user'));
    }

    public logout()
    {
        localStorage.setItem('app_user|access_token', null);
        localStorage.setItem('app_user|refresh_token', null);
        localStorage.setItem('app_user|user', null);  
    }
}