import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { User, RestUserResponse, RestUserListResponse } from './user.model';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  
  private apiPath = 'rest/auth/';
  private model = 'user';

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  all() {
    return this.http.get<RestUserListResponse>(this.getUrl() + '?limit=no&orderBy=name');
  }

  getUrl() {
    return `${environment.apiEndpoint}${this.apiPath}${this.model}`;
  }

  getUrlForId(id: number) {
    return `${this.getUrl()}/${id}`;
  }

  pick(id: number) {
    return this.http.get<RestUserResponse>(this.getUrlForId(id));
  }

  save(user: User) {
    if (user.id) // update
      return this.http.post(this.getUrlForId(user.id), user);
    else // store
      return this.http.put(this.getUrl(), user);   
  }

  destroy(id: number) {
    return this.http.delete(this.getUrlForId(id));
  }

  public index(): void
  {
    this.router.navigate([ `/${this.model}` ]);
  }

  public edit(id: number): void
  {
    this.router.navigate([ `/${this.model}/${id}/edit` ]);
  }

  public show(id: number): void
  {
    this.router.navigate([ `/${this.model}/${id}` ]);
  }

  public create(): void 
  {
    this.router.navigate([ `/${this.model}/create` ]);
  }
}
