import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { ConfigActions, ConfigActionTypes } from './config.actions';
import { Config, CidadesRadar, FonteImagems } from './config.model';

export interface ConfigState extends EntityState<Config> {
  id: number;
  cidade: string | null;
  fonte: string | null;
}

export const adapter: EntityAdapter<Config> = createEntityAdapter<Config>({
  selectId: (config: Config) => config.id,
});

export const initialState: ConfigState = adapter.getInitialState({
  id: null,
  cidade: CidadesRadar.FORTALEZA,
  fonte: FonteImagems.RADAR
});

export function configReducer(state = initialState, action: ConfigActions): ConfigState {

  switch (action.type) {
    case ConfigActionTypes.ToggleFortaleza: {
      return Object.assign({}, state, { cidade: CidadesRadar.FORTALEZA });
    }

    case ConfigActionTypes.ToggleQuixeramobim: {
      return Object.assign({}, state, { cidade: CidadesRadar.QUIXERAMOBIM });
    }

    case ConfigActionTypes.ToggleRadar: {
      return Object.assign({}, state, { fonte: FonteImagems.RADAR });
    }

    case ConfigActionTypes.ToggleSatelite: {
      return Object.assign({}, state, { fonte: FonteImagems.SATELITE });
    }

    case ConfigActionTypes.SaveConfig: {
      return adapter.upsertOne(action.payload, state);
    }

    default:
      return state;
  }
}

export const getCitySelected = (state: ConfigState) => state.cidade;
export const getSourceSelected = (state: ConfigState) => state.fonte;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of dado-sensor ids
export const selectConfigIds = selectIds;

// select the dictionary of dado-sensor entities
export const selectConfigEntities = selectEntities;

// select the array of dado-sensor
export const selectAllConfig = selectAll;

// select the total dado-sensor count
export const selectConfigTotal = selectTotal;