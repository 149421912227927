export const environment = {
  production: true,
  //apiEndpoint: 'http://localhost:8000/'
  apiEndpoint: 'https://apil5.funceme.br/'
};

export const authConfig = {
  client_id: 50,
  client_secret: 'ueuWlHUrDb6rPz0mMHlai3BSDZbQru4XbUYoaIAM',
  guest_username: 'monitor_app@funceme.br',
  guest_password: 'm0n1t0r',
  route_default: 'mapa'
}