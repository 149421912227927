import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'application';
  
  messages = this.http.get<any[]>(window.location.hostname+':4201');
  //messages = this.http.get<any[]>('http://localhost:4201');
  constructor(private http: HttpClient) {}

}
