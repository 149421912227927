import { Component, OnInit} from '@angular/core';


@Component({
  selector: 'app-radar-mini-satelite',
  templateUrl: './radar-mini-satelite.component.html',
  styleUrls: ['./radar-mini-satelite.component.css']
})
export class RadarMiniSateliteComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {

  }
}
