import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { StateModule } from './state/state.module';
import { NotificationsService } from './notifications/notifications.service';
import { UfService } from './state/uf/uf.service';
import { UserService } from './state/user/user.service';

@NgModule({
  providers: [
    UfService,
    UserService,
    NotificationsService
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    StateModule
  ],
})
export class CoreDataModule {}
